.widget {
	&--linkedin {
		margin-top: 10rem;
		display: flex;
		justify-content: center;
        background-color: var(--white);

		.sk-ww-linkedin-page-post {
			width: 70% !important;
			background-color: transparent !important;
			.linkedin-page-post-user-root-container {
				display: flex;
				justify-content: center;
			}
		}
	}
}
