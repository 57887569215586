.versus-competitor {
	&-heading {
		padding-top: 10rem;
		padding-bottom: 20rem;

		display: flex;
		// flex-wrap: wrap;

		&__title {
			margin-bottom: 7rem;
			@include font(5rem, 6rem, 700);
		}

		&__desc {
			@include font(2.4rem, 3.2rem, 600);

			p:first-child {
				margin-bottom: 3rem;
			}
		}
	}

	&-comparison {
		padding-top: 4rem;
		padding-bottom: 20rem;
		// background-color: #979797;
		// background-color: #DBDDE0;

		&__title {
			margin-bottom: 5rem;
			text-align: center;
			@include font(3.2rem, 4.7rem, 700);
		}

		&__table {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.table-row {
				display: flex;
				min-width: 850px;
				justify-content: space-between;
			}

			.table-column {
				flex-basis: calc(100% / 3);
			}

			.table-header {
				@include flex(row, center, center);

				&__heading {
					background-color: #fff4f2;
					@include font(32px, null, 700);
					color: #ff765d;
				}

				&__competitor-name {
					@include font(4rem, null, 700);
					text-transform: uppercase;
				}
			}

			.table-item {
				gap: 0.6rem;
				padding: 3rem 1.5rem;
				border-bottom: thin solid #dbdde0;

				&__icon {
					@include flex(row, center, center);
					.anticon {
						color: var(--primary);
						font-size: 1.8rem;
					}
				}
			}
		}
	}

	&-advantages {
		padding: 10rem 0 20rem;
		background-color: #fffbfb;

		&__title {
			margin-bottom: 10rem;
			text-align: center;

			@include font(3.6rem, 4.7rem, 700);
		}

		&__list {
			display: flex;
			justify-content: space-evenly;
		}

		&__item {
			// padding: 4rem 6rem;
			padding: 3rem 3rem;
			@include flex(column, null, center);
			flex-basis: calc(100% / 3 - 4rem);

			background-color: #ffeae6;
			border-radius: 5rem;
			text-align: center;

			&-img {
				margin-bottom: 3rem;
			}

			&-title {
				margin-bottom: 3rem;
				// @include font(2.5rem, 3.2rem, 700);
				@include font(2rem, 2.2rem, 700);
			}

			&-content {
				margin-bottom: 3rem;

				&--left {
					text-align: left;
				}
			}
		}
	}
}
