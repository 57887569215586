.resource-page {
	//* roi-calculator
	&--roi-calculator {
		.calculator {
			padding-bottom: 4rem;

			&-wrapper {
				padding: 5rem 15rem 4rem;
				@include flex(column);

				background-color: var(--bg-white);
				border-radius: 2rem;
				@include box-shadow(0 1px 18px 1px rgba(0, 0, 0, 0.07));
			}

			&__title {
				margin-bottom: 3rem;
				text-align: center;
				@include font(3rem, null, 600);
			}

			&__form {
				&-field {
					@include flex(column);
					margin-bottom: 3rem;

					&:nth-last-child(2) {
						width: 30%;
						margin: 0 auto 3rem;
					}

					label {
						@include font(2rem, 4.7rem, 500);
					}

					input {
						padding: 2rem;
						border-radius: 1rem;
						border: 2px solid rgba(194, 194, 194, 0.46);

						&::placeholder {
							color: var(--input-text);
							font-size: 1.8rem;
						}
					}
				}
			}

			&__roi-methodology {
				width: 60%;
				margin: auto;
				text-align: center;
				font-size: 2rem;

				a {
					display: inline;
					color: var(--primary);
					text-decoration: underline;
				}
			}
		}

		@include max-width('tablet-wide') {
			.calculator {
				&-wrapper {
					padding-left: 10rem;
					padding-right: 10rem;
				}

				&__form-field:nth-last-child(2) {
					width: 60%;
				}

				&__roi-methodology {
					width: 100%;
				}
			}
		}

		@include max-width('tablet') {
			.calculator {
				&-wrapper {
					padding-left: 5rem;
					padding-right: 5rem;
				}

				&__form-field {
					&:nth-last-child(2) {
						width: 100%;
					}

					label {
						margin-bottom: 1rem;
						@include font(1.8rem, unset);
					}
				}
			}
		}
	}

	//* Integrations
	&--integrations {
		.platforms {
			padding-bottom: 4rem;

			&-wrapper {
				@include flex();
			}

			&__sidebar {
				flex-basis: 22%;
				padding-right: 2rem;

				&-title {
					@include font(2.4rem, 4.7rem, 600);
				}

				&-list {
					@include font(1.8rem, 4.7rem, 600);
				}

				&-item {
					cursor: pointer;

					&.active {
						color: var(--primary);
					}
				}
			}

			&__main {
				flex: 1;
				-webkit-flex: 1;
				-ms-flex: 1;

				display: grid;
				grid-template-columns: repeat(auto-fill, minmax(40rem, 1fr));
				grid-gap: 2.4rem;
			}

			&__item {
				position: relative;
				padding: 2rem 2rem 7rem;
				@include flex(column, center);

				text-align: center;
				background-color: var(--bg-white);
				border-radius: 1rem;
				@include transition(0.4s cubic-bezier(0.19, 1, 0.22, 1));
				@include box-shadow(0 3px 8px 0 rgba(0, 0, 0, 0.04));

				&:hover {
					@include box-shadow(0 3px 50px 0 rgba(36, 87, 245, 0.15));
					@include transform(translateY(-2px));
				}

				&-title {
					@include font(2.4rem, 4.7rem, 600);
					margin-bottom: 2.5rem;
				}

				&-logo {
					@include mg-auto();
					margin-bottom: 3rem;
				}

				&-description {
					p {
						margin-bottom: 0.5rem;
					}
				}

				&-status {
					position: absolute;
					bottom: 0;
					left: 50%;
					width: 100%;
					margin-top: 2rem;

					line-height: 4.7rem;
					letter-spacing: 1.5px;
					text-transform: uppercase;
					border-bottom-left-radius: 1rem;
					border-bottom-right-radius: 1rem;
					transform: translateX(-50%);

					&.active {
						color: #20b656;
						background-color: #e2ffec;
					}

					&.inactive {
						color: #f7654b;
						background-color: #fff1ef;
					}
				}
			}
		}

		@include max-width('tablet') {
			.platforms {
				&-wrapper {
					flex-direction: column;
				}

				&__sidebar {
					&-list {
						@include flex();
						white-space: nowrap;
						overflow-x: scroll;
					}

					&-item:not(:last-child) {
						margin-right: 2rem;
					}
				}
			}
		}
	}

	//* Referrals
	&--referrals {
		.instructions {
			padding-bottom: 2rem;

			&-wrapper {
				padding: 4rem 6rem;
				background: var(--bg-white);
				box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.0676901);
				border-radius: 2rem;
			}

			&__title {
				margin-bottom: 1rem;
				@include font(3rem, 4rem, 600);
			}

			&__list {
				padding-left: 2rem;
				padding-right: 30%;
				list-style: disc;
			}

			&__item {
				&:not(:last-child) {
					margin-bottom: 2rem;
				}

				@include font(1.8rem, 2.3rem);
			}
		}

		.generator {
			padding-bottom: 4rem;
			text-align: center;

			&-wrapper {
				padding: 4rem;
				@include flex(column, null, center);
			}

			&__inp {
				width: 43rem;
				padding: 2rem;
				margin-bottom: 2rem;

				color: var(--input-text);
				border: 2px solid var(--input-border);
				border-radius: 1rem;
				outline: none;
			}

			&__btn {
				width: 30rem;
				margin-bottom: 2rem;
			}

			&__policy {
				a {
					color: var(--primary);
					text-decoration: underline;
				}

				&.unchecked {
					padding: 1rem;
					border: thin solid var(--primary);
					border-radius: 1rem;
				}
			}

			&__result {
				background-color: #fff1ef;
				padding: 2rem;

				&-content {
					margin-bottom: 1rem;
					@include font(3rem, 4rem, 600);
				}
			}
		}

		@include max-width('tablet-wide') {
			.instructions__list {
				padding-right: 0;
			}
		}

		@include max-width('phone') {
			&__inp,
			&__btn {
				width: 100%;
			}
		}
	}

	//* Channel Partner
	&--partner {
		.instructions {
			background-color: #f9f9fa;
			padding-top: 6rem;
			padding-bottom: 6rem;

			&__wrapper {
				@include flex(row, space-between);
			}

			&__left-side {
				flex-basis: 40%;
			}

			&__right-side {
				flex-basis: 45%;
			}

			&__heading {
				@include font(3rem, 3.5rem, 600);
				margin-bottom: 1.5rem;
			}

			&__desc {
				opacity: 0.6;
			}

			&__question-title {
				@include font(2rem, 3rem, 600);
				margin-bottom: 1.5rem;
			}

			&__question-item {
				@include flex(row, null, center);
				margin-bottom: 1rem;

				.item__icon {
					color: var(--primary);
					margin-right: 1rem;
				}

				.item__text {
					color: #212121;
					opacity: 0.6;
				}
			}
		}

		.benefits {
			padding: 4rem 14rem;

			&__heading {
				@include font(3rem, 3rem, 600);
				text-align: center;
				margin-bottom: 5rem;
			}

			&__list {
				@include flex(row, space-between, null, wrap);
			}

			&__item {
				@include flex();
				flex-basis: 45%;
				margin-bottom: 4rem;
			}

			&__icon {
				margin-right: 3rem;
			}

			&__title {
				@include font(1.8rem, 3rem, 600);
			}
		}

		.partners {
			padding-bottom: 6rem;

			&__heading {
				@include font(3rem, 3rem, 600);
				text-align: center;
				margin-bottom: 5rem;
			}

			&__list {
				@include flex(row, center, center, wrap);
			}

			&__item {
				@include flex(row, center, center, wrap);
				width: 40%;
				margin-bottom: 4rem;
			}

			&__icon {
				margin: auto;
				.with-border {
					border: 0.2px solid #bfbfbf;
				}
			}

			&__content {
				width: 70%;
			}

			&__title {
				@include font(1.8rem, 3rem, 600);
			}

			&__desc {
				max-width: 500px;
			}
		}

		.marketing {
			padding-bottom: 6rem;

			&__form {
				width: 50%;
				margin: auto;
				padding-bottom: 2rem;

				.mail__form {
					justify-content: center;
				}

				.mail__form-inp {
					flex-basis: 50%;
				}

				.mail__form-btn {
					background-color: var(--primary);
					color: white;
					font-weight: normal;
					border-width: 0;
					text-transform: unset;
				}
			}

			&__policy {
				@include font(1.4rem);
				text-align: center;
				opacity: 0.6;

				a {
					color: inherit;
					text-decoration: underline;
				}
			}
		}

		@include max-width('tablet-wide') {
			.instructions {
				&__wrapper {
					flex-direction: column;
				}

				&__left-side {
					margin-bottom: 5rem;
				}
			}

			.benefits {
				padding-right: 8rem;
				padding-left: 8rem;
			}

			.partners {
				&__list {
					flex-direction: column;
				}

				&__item {
					width: 70%;
				}
			}
		}

		@include max-width('tablet') {
			.benefits {
				padding-right: unset;
				padding-left: unset;

				&__list {
					flex-direction: column;
				}
			}

			.marketing {
				&__form {
					width: 80%;
				}
			}

			.partners {
				&__item {
					width: 100%;
				}
			}
		}

		@include max-width('phone') {
			.marketing {
				&__form {
					width: 100%;

					.mail__form {
						flex-direction: column;
					}

					.mail__form-inp {
						margin-bottom: 2rem;
						border-right-width: 1.5px;
						border-top-right-radius: 1rem;
						border-bottom-right-radius: 1rem;
					}

					.mail__form-btn {
						margin-left: 0;
						border-left-width: 1.5px;
						border-top-left-radius: 1rem;
						border-bottom-left-radius: 1rem;
					}
				}
			}
		}
	}

	//* Checklist Library
	&--checklist-library {
		.checklists {
			margin-top: 2rem;
			margin-bottom: 2rem;
			background-color: #fafafa;
			@include flex();
			min-height: 80vh;

			&__sidebar {
				width: 25%;

				background-image: url('/images/checklist_library_sidebar.png');
				background-repeat: no-repeat;
				background-position: bottom;
				background-size: contain;
			}

			&__index-btn {
				margin-top: 8%;
				margin-left: 50%;
				transform: translateX(-50%);
				padding: 1.5rem;
				color: #808080;
				background-color: inherit;
				width: 50%;

				@include font(1.6rem, 1.8rem, 700);
				border: 1.5px solid var(--primary);
				border-radius: 1rem;
				outline: none;
				cursor: pointer;
			}

			&__index-btn-primary {
				background: #f7654b;
				color: white;
			}

			&__content {
				width: 75%;
			}

			.ant-pagination.checklists-centered-pagination {
				text-align: center;
			}

			&__sorting {
				width: 50%;
				padding-top: 2rem;
				padding-bottom: 2rem;
				margin-left: 50%;
				@include flex(row, space-between, center);
			}

			&__list {
				.checklists__item {
					cursor: pointer;
					@include flex(column, space-between);
					min-height: 11rem;
					padding: 1.5rem;
					background-color: #ffffff;
					border-radius: 1rem;

					&-title {
						@include font(1.8rem, 2.4rem, 700);
					}

					&-content {
						color: var(--primary);
						@include flex(row, space-between);
					}
				}
			}

			.detail-checklist {
				&__heading {
					@include flex(row, space-between);
					padding: 1.5rem;
				}

				&__back-link {
					text-decoration: underline;
					color: #808080;
					cursor: pointer;
				}

				&__title-wrapper {
					flex-basis: 50%;
				}

				&__title {
					@include font(2.6rem, 3.8rem, 500);
					color: #151515;
				}

				&__btn-wrapper {
					@include flex(row, space-around, center);
					flex-basis: 50%;
				}

				&__review-pdf {
					width: 100%;
					min-height: 80vh;
				}
			}
		}
	}

	&--slack {
		.heading {
			padding: 4rem 0;
			text-align: center;

			&__title {
				margin-bottom: 1rem;
				@include font(3.6rem, null, 700);
			}

			&__content {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 3rem;
			}

			&__desc {
				color: #808080;
				@include font(2rem);
			}
		}

		.illustration {
			padding: 4rem 0;
			height: 60vh;

			&__wrapper {
				display: flex;
				gap: 10%;
				justify-content: space-between;
			}

			&__img-wrapper {
				width: 55%;
				height: fit-content;

				.illustration__img {
					height: 50vh;
					object-fit: contain;
				}
			}

			&__list {
				width: 35%;
				align-self: center;
			}

			&__item {
				.item__content {
					display: flex;
					justify-content: space-between;
					align-items: center;
					gap: 1rem;
				}

				.item__text {
					cursor: pointer;

					&:hover {
						border: 1px solid gray;
						transform: translateY(-2px);
						border-radius: 5px;
						padding: 4px;
					}

					&:active {
						background-color: var(--primary);
						border-color: var(--primary);
						box-shadow: 0 1px 2px 3px var(--primary);
					}
				}

				&:not(:last-child) {
					margin-bottom: 3rem;
				}
			}
		}

		.trial-cta {
			padding: 4rem 0;

			&__wrapper {
				text-align: center;
			}

			&__condition {
				margin-top: 2rem;
				font-size: 1.4rem;
				text-transform: uppercase;
			}
		}

		.guide {
			padding: 4rem 0;

			&__title {
				text-align: center;
				font-weight: bold;
				font-size: 2.8rem;
				margin-bottom: 5rem;
			}
		}

		.form-cta {
			padding: 4rem 0;
		}
	}

	&--white-labelling {
		.heading {
			padding: 4rem 0;
			text-align: center;

			&__title {
				margin-bottom: 1rem;
				@include font(3.6rem, null, 700);
			}

			&__content {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 3rem;
			}

			&__desc {
				color: #808080;
				@include font(2rem);
			}
		}

		.white-labelling-image {
			height: fit-content;
			max-width: 60%;
			margin: auto 0;
		}

		.article {
			height: 80rem;
			justify-content: space-around;
			&__title {
				margin-bottom: 1rem;
			}
			&__desc {
				a {
					color: var(--primary);
				}
			}
		}

		.description {
			.container {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				margin-bottom: 30px;
			}
			&__title {
				margin-bottom: 2rem;
				@include font(2.4rem, 3.2rem, 600);
			}
		}

		.description__format {
			margin-bottom: 2rem;
			@include font(2.1rem, 4rem, 600);
			text-align: center;
		}

		.trial-cta {
			padding: 4rem 0;

			&__wrapper {
				text-align: center;
			}

			&__condition {
				margin-top: 2rem;
				font-size: 1.4rem;
				text-transform: uppercase;
			}
		}

		.guide {
			padding: 4rem 0;

			&__title {
				text-align: center;
				font-weight: bold;
				font-size: 2.8rem;
				margin-bottom: 5rem;
			}
		}

		.form-cta {
			padding: 4rem 0;
		}
	}

	&--checklist-generator {
		.heading {
			padding: 4rem 0;
			text-align: center;

			&__title {
				margin-bottom: 1rem;
				@include font(3.6rem, null, 700);
			}

			&__content {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 3rem;
			}

			&__desc {
				color: #808080;
				@include font(2rem);
			}
		}

		.checklist-generator {
			&-wrapper {
				padding: 3rem;
				background-color: var(--bg-white);
				border-radius: 2rem;
				box-shadow: 0 1px 18px 1px rgba(0, 0, 0, 0.07);
				margin-bottom: 2rem;

				.title {
					@include font(2.1rem, 4rem, 600);
					margin-bottom: 1rem;
				}

				.first-line {
					display: flex;
					justify-content: space-between;
					margin-bottom: 1rem;
					@include font(2.4rem, null, 600);

					&-icon {
						margin-left: 2rem;
						@include font(3.5rem, null, 700);
						color: var(--primary);
						&.hover:hover {
							cursor: pointer;
						}
					}

					.title {
						@include font(2.4rem, null, 600);
						display: flex;
						align-items: center;
						i {
							margin-right: 1.5rem;
						}
					}
					.buttons {
						display: flex;
						align-items: center;
					}
				}

				.checklist-body {
					line-height: 3rem;
					&--row {
						display: flex;
						justify-content: space-between;
						align-items: center;
						.anticon:hover,
						.anticon:focus {
							color: #ff6337;
						}
					}
					.additional-btn {
						background: #ff6337;
						border: #ff6337;
						border-radius: 8px;
						margin-top: 1rem;
					}
				}

				.form {
					display: flex;
					align-items: center;
					gap: 1rem;

					&-input {
						width: 100%;
						padding: 2rem;
						border-radius: 1rem;
						border: 2px solid hsla(0, 0%, 76%, 0.46);
						font-size: 1.8rem;
						&.asset-type::placeholder {
							color: var(--input-text);
						}

						&.frequency {
							-webkit-appearance: none;
							-moz-appearance: none;
							background: transparent;
							background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
							background-repeat: no-repeat;
							background-position-x: 95%;
							background-position-y: 50%;
						}
					}
				}

				.response-text {
					white-space: pre-line;
				}

				.custom-spin {
					.ant-spin-dot-item {
						background-color: #f7654b;
					}
				}
			}
			.detail-checklist {
				padding: 1rem;
				background-color: var(--bg-white);
				border-radius: 2rem;
				box-shadow: 0 1px 18px 1px rgba(0, 0, 0, 0.07);
				margin-bottom: 2rem;
				&__heading {
					@include flex(row, space-between);
					padding: 1.5rem;
				}

				&__back-link {
					text-decoration: underline;
					color: #808080;
					cursor: pointer;
				}

				&__title-wrapper {
					flex-basis: 50%;
				}

				&__title {
					@include font(2.6rem, 3.8rem, 500);
					color: #151515;
				}

				&__btn-wrapper {
					display: flex;
					align-items: center;
					gap: 1rem;
				}

				&__review-pdf {
					width: 100%;
					min-height: 80vh;
				}
			}
		}

		.trial-cta {
			padding: 4rem 0;

			&__wrapper {
				text-align: center;
			}

			&__condition {
				margin-top: 2rem;
				font-size: 1.4rem;
				text-transform: uppercase;
			}
		}

		.guide {
			padding: 4rem 0;

			&__title {
				text-align: center;
				font-weight: bold;
				font-size: 2.8rem;
				margin-bottom: 5rem;
			}
		}

		.form-cta {
			padding: 4rem 0;
		}
	}

	//* Onboarding
	&--onboarding {
		.instructions {
			padding-bottom: 2rem;
			&-wrapper {
				padding: 4rem 6rem;
				background: var(--bg-white);
				box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.0676901);
				border-radius: 2rem;
			}

			&__title {
				margin-bottom: 1rem;
				@include font(3rem, 4rem, 600);
			}

			&__list {
				margin: 2rem 0;
				padding-right: 20%;
				padding-left: 1rem;
				ol {
					@include font(1.8rem, 2.5rem);
					li {
						margin: 1rem 0;
						i {
							color: var(--primary);
						}
						a {
							color: var(--primary);
						}
					}
				}
			}

			&__description {
				@include font(1.8rem, 2.5rem);
				margin-bottom: 2rem;
				white-space: pre-wrap;
			}

			&__item {
				&:not(:last-child) {
					margin-bottom: 2rem;
				}
				@include font(1.8rem, 2.3rem);
			}
		}

		.footer {
			background-color: white;
			display: flex;
			justify-content: center;
			&__desc {
				color: #808080;
				@include font(2rem);
			}
		}
	}

	// *Feature Requests

	&--feature-requests {
		.heading {
			&__desc {
				.bolder {
					color: rgba(0, 0, 0, 0.85);
					font-weight: 500;
				}
			}
		}

		.canny-widget {
			.spinner {
				display: flex;
				justify-content: center;
				.ant-spin-dot-item {
					background-color: #f7654b;
				}
			}

			padding: 0 10rem;
		}
	}
}
