$submenu-z-index: 20;
$triangle-z-index: 30;
$header-z-index: 100;

.header {
	width: 100%;
	padding-top: 3rem;
	position: relative;
	top: 0;
	left: 0;
	z-index: $header-z-index;

	a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

	&.is-fixed {
		padding: 1.5rem 0;
		position: fixed;
		background-color: var(--white);
		box-shadow: 0 0.2rem 1rem 0 rgba(0, 0, 0, 0.1);
	}

	&__wrapper {
		@include flex(row, space-between, center, wrap);
	}

	&__nav-mobile {
		display: none;
		padding: 1.7rem 0;
		@include font(1.8rem, null, 600);

		span {
			padding-right: 1.5rem;
			position: relative;
			cursor: default;

			&:after {
				content: '';
				position: absolute;
				top: 0.8rem;
				right: 0;
				@include size(0.7rem);

				border-right: 2px solid var(--text);
				border-bottom: 2px solid var(--text);
				@include transform(rotate(45deg));
				@include transition(0.15s linear);
			}
		}

		&--close span:after {
			@include transform(rotate(-135deg));
		}
	}

	&__nav-list {
		@include flex(row);
	}

	&__nav-item {
		padding: 1.7rem 0;
		position: relative;
		&:not(:last-child) {
			margin-right: 5rem;
		}
		@include font(1.8rem, null, 600);

		& > span {
			padding-right: 1.5rem;
			position: relative;
			cursor: default;

			&:hover {
				color: var(--primary);

				&::after {
					border-right-color: currentColor;
					border-bottom-color: currentColor;
				}
			}

			&:after {
				content: '';
				position: absolute;
				top: 0.8rem;
				right: 0;
				@include size(0.7rem);

				transform: rotate(45deg);
				border-right: 2px solid var(--text);
				border-bottom: 2px solid var(--text);
			}
		}

		&--dropdown {
			&::before {
				content: '';
				position: absolute;
				right: 0;
				z-index: $triangle-z-index;

				@include size(2rem, 1rem);
				background-color: var(--white);

				transform: translate(-12px, 44px);
				opacity: 0;
				visibility: hidden;
				transition: 0.25s linear;
			}

			&::after {
				content: '';
				position: absolute;
				right: 0;
				bottom: -1rem;
				z-index: $submenu-z-index;

				@include size(1.4rem);
				background-color: var(--white);

				border: thin solid #e2e1e4;
				@include transform(rotate(45deg) translate(-1rem, 1rem));
				opacity: 0;
				visibility: hidden;
				transition: 0.25s linear;
			}

			&:hover::before,
			&:hover::after,
			&:hover .submenu {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	&__nav-link {
		position: relative;
		color: inherit;
		border: 0;
		outline: none;

		&:hover {
			color: var(--primary);
		}
	}

	@include max-width('tablet-wide') {
		&__nav {
			display: none;
			position: fixed;
			top: 9rem;
			left: 0;
			@include size(100%);
			padding-bottom: 14rem;

			background-color: var(--white);
			overflow-y: scroll;
			-webkit-overflow-scrolling: touch;
			@include transition(0.25s linear);

			&.is-mobile {
				display: block;
			}
		}

		&__nav-mobile,
		&__nav-list {
			display: block;
		}

		&__nav-item {
			border-bottom: thin solid rgba(0, 0, 0, 0.05);
			padding-left: 2rem;

			&:not(:last-child) {
				margin-right: 0;
			}

			&--dropdown {
				&::before,
				&::after {
					all: unset;
				}

				&:hover::before,
				&:hover::after,
				&:hover .submenu {
					opacity: unset;
					visibility: unset;
				}
			}
		}
	}

	@include max-width('tablet') {
		.mail__form-inp {
			display: none;
		}
	}

	@include max-width('phone') {
		.mail__form-btn {
			display: none;
		}
	}
}

.submenu {
	min-width: 32rem;
	padding-top: 1rem;
	position: absolute;
	top: 6rem;
	left: -10rem;

	background-color: var(--white);
	border: thin solid #e2e1e4;
	border-radius: 0.8rem;
	box-shadow: 0 2px 9px 0 rgba(141, 141, 141, 0.5);

	opacity: 0;
	visibility: hidden;
	@include transition(0.25s linear);

	&__item {
		&--dropdown {
			&:hover::before,
			&:hover::after,
			&:hover .submenu-dropdown__list {
				display: block;
			}
		}

		.submenu-dropdown__list {
			display: none;
			@include transition(0.25s linear);

			@include max-width('tablet-wide') {
				display: block;
			}
		}

		.submenu-dropdown__item {
			padding: 1.2rem 2.7rem 1.2rem 4.7rem;

			&:hover {
				color: inherit;
				background-color: #ddd;
			}
		}

		.submenu-dropdown__link {
			color: unset;
		}
	}

	&__link {
		@include flex(row, null, center);
		padding: 1.2rem 2.7rem;
		color: inherit;

		&:hover {
			color: inherit;
			background-color: #ddd;
		}

		span {
			margin-left: 1.7rem;
			font-size: 1.6rem;
		}
	}

	&--resources {
		left: -26rem;
		min-width: 23rem;
		white-space: nowrap;
		display: flex;
	}

	&--building-types {
		display: flex;
		min-width: 44rem;
		left: -15rem;
		padding: 2rem;

		.dropdown__section-list {
			@include flex(row, flex-start, flex-start, wrap);
			gap: 3rem;
		}

		.dropdown__column-label {
			cursor: pointer;
			display: block;
			font-size: 14px;
			font-weight: 600;
			flex-grow: 1;
			color: var(--content);
			padding-bottom: 8px;
			line-height: 1.5;
			transition: color 0.2s ease-in-out;
			border-bottom: 2px #e1e1e1 solid;
		}

		.dropdown__column-wrapper {
			display: flex;
		}

		.dropdown__column {
			flex-direction: column;
			margin-left: 0 !important;
		}

		.dropdown__item {
			padding: 1rem;

			&:hover {
				color: inherit;
				background-color: #ddd;
			}
		}

		.dropdown__link {
			font-weight: 500;
			font-size: 16px;
			color: var(--content);
			position: relative;
			height: 100%;
			letter-spacing: 0;
			display: flex;
			align-items: center;
			span {
				margin-left: 1rem;
			}
		}
	}

	&--features {
		display: flex;
		min-width: 75rem;
		left: -15rem;
		padding: 2rem;

		.dropdown__section-list {
			@include flex(row, flex-start, flex-start, wrap);
			gap: 3rem;
		}

		.dropdown__column-label {
			display: block;
			font-size: 14px;
			font-weight: 600;
			flex-grow: 1;
			color: var(--content);
			padding-bottom: 8px;
			line-height: 1.5;
			transition: color 0.2s ease-in-out;
			border-bottom: 2px #e1e1e1 solid;
		}

		.dropdown__column-wrapper {
			display: flex;
		}

		.dropdown__column {
			flex-direction: column;
			margin-left: 0 !important;
		}

		.dropdown__item {
			padding: 1rem;

			&:hover {
				color: inherit;
				background-color: #ddd;
			}
		}

		.dropdown__link {
			font-weight: 500;
			font-size: 16px;
			color: var(--content);
			position: relative;
			height: 100%;
			letter-spacing: 0;
			display: flex;
			align-items: center;
			span {
				margin-left: 1rem;
			}
		}
	}

	@include max-width('tablet-wide') {
		width: 100%;
		min-width: 0;
		display: none;
		position: initial;
		top: 0;
		left: 0;

		background-color: unset;
		border: 0;
		border-radius: 0;
		box-shadow: unset;
		opacity: 1;
		visibility: visible;

		&.is-mobile {
			display: block;
		}

		&--features {
			.dropdown__section-list {
				flex-direction: column;
				gap: 1rem;
			}

			.dropdown__section {
				width: 100%;
			}

			.dropdown__column-wrapper {
				flex-direction: column;
			}
		}
	}
}
