.ant-checkbox-wrapper:hover .ant-checkbox-inner, 
.ant-checkbox:hover .ant-checkbox-inner, 
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--primary);
}

.ant-checkbox-checked {
  &::after {
    border-color: var(--primary);
  }

  .ant-checkbox-inner {
    background-color: var(--primary);
    border-color: var(--primary);
  } 
}