.sign-up {
	padding: 3rem 12rem 4rem;

	&__title-wrapper {
		margin-bottom: 4rem;
	}

	&__title {
		margin-bottom: 1.2rem;
		@include font(3rem, 4rem, 600);

		span {
			color: var(--primary);
		}
	}

	&__subtitle {
		color: #808080;
		@include font(2rem, 2.6rem);
	}

	&__form {
		margin-bottom: -2rem;

		&-row {
			margin-bottom: 2rem;
			@include flex(row, space-between);
		}

		&-field {
			width: 49%;
			text-align: left;
			@include flex(column);

			label {
				@include font(2rem, 4.7rem, 500);
			}

			input {
				padding: 1.6rem;
				font-size: 1.8rem;
				border: 1.5px solid var(--input-border);
				border-radius: 1rem;
				outline: none;

				&::placeholder {
					color: var(--input-text);
				}
			}
		}
	}

	&__message {
		width: 49%;
		margin: auto;
		color: var(--primary);
		font-size: 1.8rem;
		text-transform: uppercase;
	}

	&__password-requirement {
		width: 49%;
		color: var(--input-text);
		font-size: 1.8rem;
		text-align: left;
	}

	&__policy {
		@include mg-auto();

		a {
			color: var(--text);
			text-decoration: underline;
			letter-spacing: 0.1rem;
		}
	}

	@include max-width('tablet-wide') {
		padding-right: 3rem;
		padding-left: 3rem;
	}

	@include max-width('tablet') {
		&__form {
			&-row {
				flex-direction: column;

				&:nth-child(1) {
					margin-bottom: 1rem;
				}
			}

			&-field {
				width: 100%;
			}
		}

		&__message,
		&__password-requirement {
			width: 100%;
		}

		&__message {
			order: 1;
			margin-top: 1rem;
		}
	}

	@include max-width('phone') {
		padding-right: 0;
		padding-left: 0;
		padding-bottom: 0;

		&__title-wrapper {
			margin-bottom: 2rem;
		}
	}
}
