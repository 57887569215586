.invite {
  width:100vw;
  height: 100vh;

  &--banner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    height: 100vh;
    background-color: rgba(247, 101, 75, .9);
  }

  &--sign-up {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    height: 100vh;
  }
}