.form-cta {
	padding-bottom: 7rem;

	&__wrapper {
		padding: 3.5rem 7.5rem;
		@include flex(row, space-between, center);

		background-image: url("/images/bg-cta.png");
		background-position: center;
    background-repeat: no-repeat;
		object-fit: cover;
	}

	&__heading {
		max-width: 434px;

		h2 {
			color: var(--white);
			@include font(4rem, 5rem, bold);
		}
	}

	&__content {
		@include flex(column, space-between, null, wrap);
	}

	&__signup {
		margin-bottom: 2.6rem;
		@include flex(column);
	}

	&__signup-desc {
		margin-top: 1.6rem;
		color: var(--white);
		@include font(1.4rem, null, 400);
		text-transform: uppercase;
	}

	&__mail {
		.mail__form {
			&-inp,
			&-btn {
				padding: 2rem;
				border-color: #ffb5aa;
			}

			&-inp {
				&::placeholder {
					color: var(--white);
				}

				width: 60%;
				color: var(--white);
				background-color: #ff745f;
			}

			&-btn {
				width: 40%;
				margin-left: 0;
				color: var(--white);
				background-color: #dc462f;

				border-left-width: 0;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
	}

	&__policy {
		width: 70%;
		margin-top: 1.8rem;
		opacity: 0.7;

		&,
		a {
			color: var(--white);
			@include font(1.2rem, null, 600);
		}

		a {
			text-decoration: underline;
			letter-spacing: 0.1rem;
			opacity: 1;
		}
	}

	//!Responsive

	@include max-width("tablet-wide") {
		.container {
			@include pd-0();
		}

		&__wrapper {
			background-size: cover;
			flex-direction: column;
			padding-right: 4rem;
			padding-left: 4rem;
		}

		&__heading {
			width: 100%;
			max-width: unset;
			margin-bottom: 2rem;
			text-align: center;
		}

		&__content {
			width: 100%;
			@include flex(column);
			text-align: center;
		}

		&__signup {
			margin-bottom: 0;
			@include flex(column, center, center, wrap);
		}

		.btn--cta-signup {
			width: 80%;
			@include pd-15();
		}

		&__signup-desc {
			width: 100%;
		}

		&__mail {
			@include flex(column, center, center, wrap);

			.mail__form {
				width: 80%;
			}
		}

		&__policy {
			width: 100%;
		}
	}

	@include max-width("tablet") {
		&__wrapper {
			padding-right: 2rem;
			padding-left: 2rem;
		}

		&__heading h2 {
			@include font(3rem, 3rem);
		}

		&__signup {
			margin-bottom: 2rem;
		}

		.btn--cta-signup,
		&__mail .mail__form {
			width: 100%;
		}
	}

	@include max-width("phone") {
		&__mail {
			.mail__form {
				&-inp {
					display: none;
				}

				&-btn {
					width: inherit;
					margin-left: 0;
					border-left-width: 1.5px;
					border-top-left-radius: 1.5rem;
					border-bottom-left-radius: 1.5rem;
				}
			}
		}
	}
}
