.mail__form {
  @include flex(row, space-between);

  &-inp,
  &-btn {
    padding: 1.6rem;
    border: 1.5px solid var(--input-border);
    border-radius: 1rem;
    outline: none;
  }

  &-inp {
    padding-top: 1.3rem;
    color: var(--input-text);
    @include font(1.8rem);

    border-right-width: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &-btn {
    margin-left: -1rem;
    color: var(--primary);
    background-color: var(--white);

    @include font(1.6rem, 1.8rem, 700);
    text-transform: uppercase;
    border-color: currentColor;
    cursor: pointer;
  }
}