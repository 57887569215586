.brochure {
	@include flex(row);
	gap: 2rem;

	&__left {
		width: 40%;
		height: auto;
		padding: 2rem;
		background-color: #f1f3f9;
	}

	&__right {
		@include flex(column, space-evenly);
	}

	&__title-wrapper {
		text-align: left;
	}

	&__title {
		margin-bottom: 1rem;
		@include font(3rem, 4rem, 600);

		span {
			color: var(--primary);
		}
	}

	&__subtitle {
		color: #808080;
		@include font(2rem, 2.6rem);
	}

	&__form {
		&-field {
			text-align: left;
			@include flex(column);

			&:not(:last-child) {
				margin-bottom: 1rem;
			}

			label {
				@include font(2rem, 4.7rem, 500);
			}

			input {
				padding: 1.6rem;
				font-size: 1.8rem;
				border: 1.5px solid var(--input-border);
				border-radius: 1rem;
				outline: none;

				&::placeholder {
					color: var(--input-text);
				}
			}

      &--w50 {
        width: 50%;
        margin: auto;
      }
		}
	}

	&__policy {
		@include mg-auto();

		a {
			color: var(--text);
			text-decoration: underline;
			letter-spacing: 0.1rem;
		}
	}

	@include max-width('tablet-wide') {
		flex-direction: column;

		&__left {
			margin: auto;
		}
	}

	@include max-width('tablet') {
		&__title {
			@include font(2.5rem);
		}

		&__subtitle {
			@include font(1.5rem);
		}
	}
}
