.fc-theme.ant-collapse {
	width: 100%;
	background-color: transparent;
	border: none;

	.ant-collapse-item {
		padding: 2.8rem;
		color: var(--heading);

		@include font(2.4rem, 4.7rem, 600);
		border-bottom: thin solid #d2d2d2;

		.ant-collapse-header {
			max-width: 80rem;
			margin: 0 auto;
		}

		.ant-collapse-content {
			max-width: 50rem;
			margin: auto;
			background-color: transparent;
			line-height: 3.5rem;
			border-top: none;

			.answer-content {
				.answer-content--body {
					color: #4a4a4a;
					font-size: 1.6rem;

					p {
						padding: 1rem 0;

						a {
							color: currentColor;
							text-decoration: underline;
						}
					}
				}
			}
		}
	}

	.ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
		left: 0px;
	}
}
