:root {
	--primary: #f7654b;

	--heading: #2f2f2f;
	--text: #676767;
	--text-gray: #b2b2b2;

	--font: 'Sarabun';
	--promo-font: 'Noteworthy';

	--input-border: #dadada;
	--input-text: #acacac;

	--bg-light: #f7f7f7;
	--bg-gray: #f3f5f6;

	--white: white;
	--black: black;

	--container: 133rem;
	--sm-container: 113rem;
}

@font-face {
	font-family: 'Noteworthy';
	src: url('/fonts/Noteworthy.ttf') format('tff');
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

html {
	font-size: 62.5%;
	scroll-behavior: smooth;
}

body {
	color: var(--text);
	background-color: var(--white);
	font-family: var(--font), sans-serif;
	@include font(1.6rem, 1.4, 400);
}

img {
	display: block;
	max-width: 100%;
}

a {
	display: inline-block;
	text-decoration: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.wrapper {
	position: relative;
	overflow-x: hidden;

	@include max-width('tablet-wide') {
		&.no-scroll {
			height: 100vh;
			overflow: hidden;
		}
	}
}

.container {
	max-width: var(--container);
	@include mg-auto();
	@include pd-15();
}

.sm-container {
	max-width: var(--sm-container);
	@include mg-auto();
	@include pd-15();
}

//!Common
.heading-container {
	padding-top: 4rem;
	padding-bottom: 6rem;

	.heading {
		margin: auto;
		text-align: center;

		&__title {
			margin-bottom: 1rem;
			@include font(3.6rem, null, 700);

			span {
				color: var(--primary);
			}
		}

    &__content {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3rem;
    }

		&__desc {
			color: #808080;
			@include font(2rem);

			span {
				color: var(--primary);
			}

      &--mb-1 {
        margin-bottom: 1rem;
      }
		}

		&__integrations {
			margin-top: 2rem;

			a {
				color: var(--primary);
				text-decoration: underline;
			}
		}

		@include max-width('tablet') {
			&__title {
				@include font(3rem);
			}

			&__desc {
				@include font(1.8rem);
			}
		}
	}
}

.pricing-wrapper {
  padding-bottom: 8rem;
  text-align: center;

  &__title {
    margin-bottom: 2rem;
    @include font(2rem, null, 500);
  }
}

// Responsive video
.player-wrapper {
	position: relative;
	padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

	.react-player {
		position: absolute;
		top: 0;
		left: 0;
	}
}
