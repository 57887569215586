.inf-slider-containter {
  margin-top: 1em;
  padding: 1em 0;
  width: 100%;
  display: flex;
  gap: 0;
  overflow-x: hidden;;
  
  .logos-slide {
    padding: 0.5em 0;
    display: flex;
    gap: 4em;
    padding-left: 4em;
    animation: slide infinite 50s linear;
  }

  img {
    display: unset;
    max-width: unset;
    height: 85px;
  }
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}