.ant-modal-wrap.popup-wrapper {
	.ant-modal {
		max-width: calc(100vw - 16px);
		margin: 8px auto;

		&-content {
			border-radius: 1rem;
		}

		&-body {
			text-align: center;
		}
	}

	//!roi-result
	.result {
		padding-top: 4rem;
		padding-bottom: 4rem;

		&__main {
			margin-bottom: 6rem;

			&-title {
				@include font(2.4rem, 4.7rem, 500);

				span {
					color: var(--primary);
				}
			}

			&-saving {
				color: var(--primary);
				@include font(8rem, 10.4rem, bold);
			}

			&-annual {
				color: #808080;
				@include font(2rem);
			}
		}

		&__cta {
			margin: 0 auto 5rem;
			width: 45%;

			.mail__form {
				justify-content: center;

				&-inp,
				&-btn {
					padding: 2rem;
				}

				&-inp {
					width: 60%;
				}

				&-btn {
					width: 40%;
					padding-top: 2.3rem;
					color: var(--white);
					background-color: var(--primary);
					border-color: var(--primary);
				}
			}
		}

		&__footer {
			font-size: 2rem;

			a {
				display: inline;
				color: var(--primary);
			}
		}

		@include max-width('tablet-wide') {
			&__cta {
				width: 65%;
			}
		}

		@include max-width('tablet') {
			&__main,
			&__cta {
				margin-bottom: 3rem;
			}

			&__cta {
				width: 100%;
			}
		}

		@include max-width('phone') {
			&__main {
				&-title,
				&-saving {
					line-height: unset;
				}

				&-saving {
					font-size: 5rem;
				}
			}

			&__cta {
				.mail__form {
					flex-direction: column;

					&-inp,
					&-btn {
						width: 100%;
						margin-bottom: 1rem;
					}

					&-inp {
						border-right-width: 1.5px;
						border-top-right-radius: 1rem;
						border-bottom-right-radius: 1rem;
					}

					&-btn {
						margin-left: 0;
					}
				}
			}
		}
	}
}