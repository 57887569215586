.ant-notification {
	&-notice {
		border-radius: 1rem;

		&-message {
			color: #ff4d4f;
		}

		&-description {
			font-size: 16px;
		}
	}
}
