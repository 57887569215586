//* Tagline animation
@keyframes fadeInAndOut {
	0% {
		opacity: 0;
		height: auto;
	}

	16% {
		opacity: 1;
	}

	33% {
		opacity: 0;
		height: 0px;
	}

	100% {
		opacity: 0;
		height: 0px;
	}
}

// !hero
.hero {
	padding: 5rem 0 7rem;

	&__wrapper {
		@include flex();
	}

	&__content {
		width: 60%;

		@include flex(column, space-between, null, wrap);

		&-heading-wrapper {
			width: 100%;
			height: 25rem;
			position: relative;
		}

		&-heading {
			height: 0;
			opacity: 0;
			color: var(--heading);
			@include font(4.5rem, 6rem, bold);
			letter-spacing: 1px;
		}

		&-heading-wrapper > &-heading:nth-child(1) {
			animation-name: fadeInAndOut;
			animation-duration: 15s;
			animation-iteration-count: infinite;
			// animation: fadeInAndOut 15s infinite;
		}
		&-heading-wrapper > &-heading:nth-child(2) {
			animation-name: fadeInAndOut;
			animation-duration: 15s;
			animation-iteration-count: infinite;
			animation-delay: 5s;
			// animation: fadeInAndOut 15s 5s infinite;
		}
		&-heading-wrapper > &-heading:nth-child(3) {
			animation-name: fadeInAndOut;
			animation-duration: 15s;
			animation-iteration-count: infinite;
			animation-delay: 10s;
			// animation: fadeInAndOut 15s 10s infinite;
		}

		&-cta {
			display: flex;
			flex-wrap: wrap;
			gap: 9rem;
			justify-content: space-between;

			.cta-wrapper {
				position: relative;
				&::after {
					content: 'no credit card required';
					position: absolute;
					top: 8rem;
					left: 0;
					z-index: -1;
					font-size: 1.4rem;
					text-transform: uppercase;
				}
			}

			.brochure {
				position: relative;
				&::before {
					content: 'or download brochure';
					position: absolute;
					top: -4rem;
					left: 0;
					z-index: -1;

					color: var(--primary);
					font-size: 2rem;
					text-transform: capitalize;
				}
			}

			.embed-img-link {
				order: 2;
				display: flex;
				align-self: flex-start;
				min-width: 250px;
				justify-content: space-around;
				border: 2px solid var(--white);
				border-radius: 1rem;
				box-shadow: 1px 1px 2px 1px #2d3436;
				padding: 6px;

				span {
					display: block;
					font-size: 2.5rem;
					color: initial;
					margin-top: unset;
					text-transform: unset;
					text-decoration: none;
					margin-top: auto;
					margin-bottom: auto;

					b {
						font-weight: bold;
					}
				}
			}

			span {
				display: block;
				margin-top: 2rem;
				font-size: 1.4rem;
				text-transform: uppercase;
			}
		}

		&-mail {
			@include flex(row, space-between);
			margin-top: 6.6rem;
			.mail {
				&--w60 {
					width: 60%;
				}
			}

			.mail__header {
				display: block;
				margin-bottom: 1.6rem;

				color: var(--primary);
				font-size: 2rem;
				text-transform: capitalize;
			}

			.mail__form {
				justify-content: unset;
			}

			.mail__footer {
				display: block;
				margin-top: 1.6rem;
				color: var(--text-gray);

				& > a {
					color: inherit;
					letter-spacing: 0.1rem;
					text-decoration: underline;
				}
			}
		}

		&-email {
			display: flex;
			justify-content: flex-end;
			@include max-width('tablet') {
				margin-top: 1rem;
				justify-content: flex-start;
			}
			.mail__header {
				display: flex;
				margin-bottom: 1.6rem;
				color: var(--primary);
				font-size: 2rem;
				.email-link {
					@extend .mail__header;
					text-decoration: underline;
				}
			}
		}
	}

	&__stats {
		width: 40%;
	}

	&__row {
		@include flex(row, flex-end, flex-end, null, 0.8rem);

		&:not(:last-child) {
			margin-bottom: 1.6rem;
		}
	}

	&__block {
		padding: 3rem;
		@include size(19.5rem);
		@include flex(column, flex-end);

		border-radius: 1.5rem;
		box-shadow: inset -9px -9px 9px 0 rgba(174, 174, 192, 0.25), inset 9px 9px 9px 0 #ffffff, -9px -9px 27px 0 #ffffff,
			9px 9px 27px 0 rgba(174, 174, 192, 0.4);

		.stat-text {
			font-size: 1.4rem;

			&--after {
				position: relative;
			}
		}

		.stat-number {
			position: relative;
			@include font(3.6rem, null, bold);

			&--after:after {
				content: 'sqm';
				position: absolute;
				right: -5px;
				top: -10px;
				font-size: 1.4rem;
			}

			&--after:before {
				content: 'in 10 countries';
				position: absolute;
				bottom: -15px;
				font-size: 1.4rem;
			}
		}

		&--lg {
			@include size(24rem);
		}

		&--md {
			@include size(11rem);
		}

		&--sm {
			@include size(5.6rem);
		}

		&--align-start {
			align-self: flex-start;
		}
	}

	&__img-wrapper {
		order: 2;
		flex-basis: 50%;

		display: flex;
		// justify-content: center;
		// gap: 1rem;
		// margin-left: 3rem;
		justify-content: flex-end;
		gap: 0.8rem;
		margin-left: 6rem;

		img {
			// height: 10rem;
			height: 11rem;
		}
	}

	//!Responsive

	@include max-width('tablet-wide') {
		padding: 3rem 0 5rem;

		&__content {
			width: 100%;

			&-cta {
				margin-bottom: 3rem;
			}
		}

		&__stats {
			display: none;
		}
	}

	@include max-width('tablet') {
		&__content {
			flex-direction: column;

			&-heading {
				font-size: 4.5rem;
			}

			&-mail {
				flex-direction: column;

				.mail {
					margin-top: 0.5rem;
					width: unset;
				}
			}
		}
	}

	@include max-width('tablet') {
		&__content-heading {
			font-size: 3.3rem;
			line-height: unset;
		}
	}
}

// !explainer video
.explainer-video {
	padding-top: 5rem;
}

// !demo
.demo {
	padding: 6.5rem 0;
	background-color: var(--bg-gray);

	&__wrapper {
		@include flex(row, space-between);
	}

	&__heading {
		width: 40%;
	}

	&__content {
		margin-bottom: 5rem;

		&-title {
			@include font(3.6rem, null, bold);
			margin-bottom: 1.6rem;
		}

		&-desc {
			font-size: 2rem;
			text-transform: capitalize;
		}
	}

	&__channels {
		@include flex(row, center, center, wrap);

		&-item {
			margin-top: 3rem;
			margin-right: 2rem;
			flex-shrink: 0;
		}
	}

	&__illustration {
		height: auto;
		width: 50%;
	}

	//!Responsive

	@include max-width('tablet-wide') {
		padding: 4.5rem 0;

		&__wrapper {
			flex-direction: column;
			align-items: center;
		}

		&__heading {
			width: 100%;
			margin-bottom: 5rem;
		}

		&__content {
			margin-bottom: 0;
		}

		&__illustration {
			width: 80%;
		}
	}
}

// !customers
.customers {
	padding: 5rem 0 0;

	&__wrapper {
		@include flex(column, null, center);
	}

	&__heading {
		margin: 0 auto;
		@include font(3.6rem, null, bold);
	}

	&__logo {
		padding-top: 5rem;
		padding-bottom: 5rem;
	}
}

// !pricing plans - features
.bg-img__wrapper {
	position: relative;
	background-image: linear-gradient(var(--bg-gray), var(--white));

	$bg-img-z-index: 10;
	$child-z-index: 11;

	.bg-img {
		@include size(48rem);
		position: absolute;
		z-index: $bg-img-z-index;

		border-radius: 9.8rem;
		opacity: 0.04;
		background: linear-gradient(137.35deg, rgba(255, 255, 255, 0.0001) 2.28%, #ff6d53 61.44%);

		&--lb {
			top: 50%;
			left: -23%;
			@include transform(rotate(-33deg));
		}

		&--r {
			top: 37%;
			right: -12%;
			@include transform(matrix(-0.84, -0.54, -0.54, 0.84, 0, 0));
		}

		&--rb {
			top: 71%;
			right: -20%;
			@include transform(matrix(-0.84, -0.54, -0.54, 0.84, 0, 0));
		}
	}

	.pricing-plans {
		padding-top: 5rem;
		position: relative;
		z-index: $child-z-index;

		&__wrapper {
			margin-bottom: 3rem;
			@include flex(column, null, center);
		}

		&__heading {
			margin-bottom: 6rem;
			text-align: center;
			@include font(3.6rem, null, bold);
		}

		&__content {
			position: relative;

			.pricing-currency-select {
				position: absolute;
				right: 0;
				top: 2%;
				z-index: 999;
			}
		}

		&__list {
			@include flex(row);
			padding: 2.6rem 0;
			background-color: var(--white);
			border-radius: 1rem;
			box-shadow: 0 8px 18px 1px rgba(0, 0, 0, 0.07);
		}

		&__item {
			width: 25%;
			padding: 0 4rem;
			text-align: center;

			&:not(:last-child) {
				border-right: thin solid #ececec;
			}

			&-title {
				white-space: nowrap;
				@include font(2.4rem, 4.7rem, 600);
			}

			&-price {
				@include flex(column, center);
				min-height: 11rem;

				.value {
					@include font(4rem, 4.7rem, bold);
				}

				.desc {
					@include font(1.8rem, 2.3rem);

					&--font-2rem {
						@include font(2rem);
					}
				}

				.psg-claim {
					margin-top: 1rem;
					font-size: 1.7rem;

					.claim {
						color: var(--primary);
					}
				}
				&--min-height-33rem {
					min-height: 33rem;
				}
			}

			&-add-ons {
				min-height: 10rem;
				@include flex(column, space-around);
				margin-bottom: 1rem;
				row-gap: 0.5rem;

				.add-ons {
					&__title {
						text-transform: capitalize;
						color: var(--primary);
						font-weight: bold;
					}

					&__accounts,
					&__account-price {
						@include font(1.8rem, null, bold);
					}
				}
			}

			&-benefits {
				margin-top: 5rem;

				.benefit {
					margin-bottom: 1.6rem;
					@include flex();

					&__icon {
						color: #5389ff;

						&--disabled {
							color: #dadada;
						}
					}

					&__text {
						margin-left: 1.6rem;
						font-size: 1.4rem;
						text-align: initial;
					}
				}
			}
		}

		&__sme {
			@include flex(row, center, center);
		}

		//!Responsive
		@include max-width('tablet-wide') {
			&__list {
				padding: 0;
				@include flex(column);
				background-color: unset;
				box-shadow: unset;
			}

			&__item {
				width: 100%;
				padding-bottom: 2rem;
				background-color: var(--white);
				border-radius: 1rem;
				box-shadow: 0 8px 18px 1px rgba(0, 0, 0, 0.07);

				&:not(:last-child) {
					margin-bottom: 2rem;
					border-right: 0;
				}

				&-title {
					margin: 1rem 0 1.5rem;
				}

				&-price {
					min-height: unset;
					margin-bottom: 1rem;
				}
			}

			&__sme {
				flex-direction: column;

				&-link {
					width: 70%;
					margin-bottom: 1rem;
					text-align: -webkit-center;
				}
			}
		}
	}

	.features {
		padding-top: 5rem;
		padding-bottom: 7rem;
		position: relative;
		z-index: $child-z-index;

		$illustration-z-index: -10;

		&__row {
			@include flex(row, space-between, null, wrap);

			&--pb-10rem {
				padding-bottom: 10rem;
			}

			&--mb-3rem {
				margin-bottom: 3rem;
			}

			&--mb-7rem {
				margin-bottom: 7rem;
			}
		}

		&__content {
			width: 50%;
			@include flex(column, center);

			&--w70 {
				width: 70%;
			}
		}

		&__illustration {
			height: auto;
			max-width: 60rem;
			width: 40%;

			&--absolute {
				position: absolute;
				right: 0;
				bottom: 0;
				z-index: $illustration-z-index;
			}
		}

		&__introduce {
			margin-bottom: 10rem;
			@include font(3rem, null, 600);

			span {
				color: var(--primary);
			}

			&--pd-5 {
				padding: 5rem 0;
			}
		}

		&__article {
			width: calc(100% / 2 - 4rem);

			&-icon {
				@include size(5rem);
				padding: 1.5rem;
				margin-bottom: 1rem;

				background-color: #ffeae6;
				text-align: center;
				border-radius: 1rem;
			}

			&-title {
				display: inline-block;
				@include font(1.8rem, null, 600);
			}

			&-desc {
				opacity: 0.6;
			}

			&--divided-3 {
				width: calc(100% / 3 - 4rem);
			}
		}

		//!Responsive

		@include max-width('tablet') {
			&__introduce {
				margin-bottom: 5rem;
			}

			&__row {
				flex-direction: column;
				align-items: center;

				&--article {
					flex-direction: row;
					align-items: baseline;
				}
			}

			&__content {
				width: 100%;
			}

			&__article--divided-3 {
				width: calc(100% / 2 - 4rem);
				margin-bottom: 3rem;
			}

			&__illustration {
				width: 80%;
			}
		}

		@include max-width('phone') {
			&__row--article {
				flex-direction: column;
				margin-bottom: unset;
			}

			&__row--pb-30rem {
				padding-bottom: 30rem;
			}

			&__article {
				width: 100%;
				margin-bottom: 3rem;
			}
		}
	}
}
