.exit-intent {
	@include flex(row);
	gap: 2rem;

	&__left {
		width: 40%;
		height: auto;
		padding: 2rem;
		background-color: #f1f3f9;
	}

	&__right {
		width: 60%;
		@include flex(column, space-evenly);
	}

	&__title-wrapper {
		text-align: center;
    margin-bottom: -4rem;
	}

	&__title {
		display: block;
		margin-bottom: 2rem;
		@include font(4rem, 4rem, 600);

		span {
			color: var(--primary);
		}
	}

	&__subtitle {
		display: block;
		margin-bottom: 1.5rem;
		@include font(2rem, 2.6rem);
		color: #808080;

		&--mt-2 {
			margin-top: 1.5rem;
			margin-bottom: unset;
		}
	}

	&__form {
		&-field {
			text-align: left;
			@include flex(column);

			&:not(:last-child) {
				margin-bottom: 1rem;
			}

			label {
				@include font(2rem, 4.7rem, 500);
			}

			input {
				padding: 1.6rem;
				font-size: 1.8rem;
				border: 1.5px solid var(--input-border);
				border-radius: 1rem;
				outline: none;

				&::placeholder {
					color: var(--input-text);
				}
			}

			&--w50 {
				width: 50%;
				margin: auto;
			}
		}
	}

	&__policy {
		@include mg-auto();

		a {
			color: var(--text);
			text-decoration: underline;
			letter-spacing: 0.1rem;
		}
	}

	@include max-width('tablet-wide') {
		flex-direction: column;

		&__left {
			margin: auto;
		}
	}

	@include max-width('tablet') {
		&__title {
			@include font(2.5rem);
		}

		&__subtitle {
			@include font(1.5rem);
		}
	}
}
