//!======Responsive======

$breakpoints: (
	'phone': 480px,
	'tablet': 768px,
	'tablet-wide': 1024px,
	'desktop': 1200px,
);

@mixin max-width($width, $type: max) {
	@if map_has_key($breakpoints, $width) {
		$width: map_get($breakpoints, $width);
		@if $type == max {
			$width: $width - 1px;
		}
		@media only screen and (max-width: scut-em($width)) {
			@content;
		}
	}
}

//!======Block======

@mixin mg-auto {
	margin: {
		left: auto;
		right: auto;
	}
}

@mixin pd-0 {
	padding: {
		right: 0;
		left: 0;
	}
}

@mixin pd-15 {
	padding: {
		right: 1.5rem;
		left: 1.5rem;
	}
}

@mixin flex($direction: row, $justify: null, $align: null, $wrap: null, $gap: null) {
	display: flex;
	flex-direction: $direction;
	justify-content: $justify;
	align-items: $align;
	flex-wrap: $wrap;
	gap: $gap;
}

//!======Element======

@mixin size($width, $height: $width) {
	width: $width;
	height: $height;
}

@mixin font($size: null, $lineHeight: null, $weight: null) {
	@if $size != null {
		font-size: $size;
	}

	@if $lineHeight != null {
		line-height: $lineHeight;
	}

	@if $weight != null {
		font-weight: $weight;
	}
}

@mixin border-radius($all: null, $tl: $all, $tr: $all, $btl: $all, $btr: $all) {
	border-top-left-radius: $tl;
	border-top-right-radius: $tr;
	border-bottom-right-radius: $btr;
	border-bottom-left-radius: $btl;
}

@mixin box-shadow($property...) {
	-webkit-box-shadow: $property;
	-moz-box-shadow: $property;
	box-shadow: $property;
}

@mixin transform($property) {
	-webkit-transform: $property;
	-moz-transform: $property;
	-ms-transform: $property;
	-o-transform: $property;
	transform: $property;
}

@mixin transition($property...) {
	-webkit-transition: $property;
	-moz-transition: $property;
	-ms-transition: $property;
	-o-transition: $property;
	transition: $property;
}
