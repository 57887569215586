// button
.btn {
	padding: 2rem;
	color: var(--white);
	background-color: var(--primary);

	@include font(1.8rem, 2.3rem);
	text-transform: uppercase;
	text-decoration: none;

	border-radius: 1rem;
	border: 0;
	outline: none;
	cursor: pointer;

	a {
		color: inherit;
	}

	&--cta {
		@include font(2rem, 2.6rem, bold);
		&-video-demo {
			@include font(2rem, 2.6rem, bold);
			width: 252.35px;
		}
	}

	&--cta-signup {
		color: var(--primary);
		background-color: var(--white);
		font-weight: bold;
	}

	&--cta-plan {
		width: 70%;
		color: var(--primary);
		background-color: var(--white);
		border: thin solid var(--primary);
	}

	&--cta-register {
		width: 35%;
		@include mg-auto();

		@include max-width('tablet-wide') {
			width: 55%;
		}

		@include max-width('tablet') {
			width: 100%;
		}
	}

	&--plans {
		padding-right: 3rem;
		padding-left: 3rem;
	}

	&--copy {
		color: var(--primary);
		background-color: inherit;
		border: 1.5px solid currentColor;
	}

	&--copy {
		color: var(--primary);
		background-color: inherit;
		border: 1.5px solid currentColor;
	}

	&--checklist {
		padding: 1rem 2rem;
		background-color: var(--white);
		color: #808080;
		@include font(1.6rem, 2.1rem, 500);
		text-transform: none;
		border: thin solid var(--primary);

		&:hover {
			background-color: var(--primary);
			color: var(--white);
		}
	}

	&--help {
		padding: 1.2rem;
		text-transform: capitalize;
		background-color: #007eff;
	}
}
