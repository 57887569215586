.feature {
	&-video {
		padding-bottom: 7rem;
	}

	&-articles {
		padding-bottom: 5rem;

		&__wrapper {
			@include flex(row, space-between, null, wrap);
		}
	}

	&-article {
		width: calc(100% / 4 - 4.2rem);

		&__icon {
			margin-bottom: 1.5rem;
		}

		&__title {
			margin-bottom: 0.7rem;
			@include font(1.8rem, null, 600);
		}

		&__desc {
			color: rgba(103, 103, 96, 0.6);
			@include font(1.4rem, 1.8rem);
		}

		&--w50 {
			width: calc(100% / 2 - 2.8rem);
		}
	}

	//!Responsive
	@include max-width('tablet-wide') {
		&-articles__wrapper {
			margin-bottom: -2rem;
		}

		&-article {
			width: calc(100% / 2 - 2.8rem);
			margin-bottom: 2rem;
		}
	}

	@include max-width('tablet') {
		&-article {
			width: 100%;
		}
	}
	&__custom-icon {
		background-color: #ffeae6;
		aspect-ratio: 1 / 1;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 56px;
		border-radius: 1rem;
	}
}
