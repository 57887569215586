.building-type {
	&__articles {
		padding-bottom: 8rem;

		&-wrapper {
			@include flex(column);
		}
	}

	&__article {
		@include flex(row, space-around);
    position: relative;

		&:not(:last-child) {
			margin-bottom: 6rem;
		}

		&:nth-child(even) &-img {
			order: 2;
    }

    &-img {
      height: auto;
      max-width: 50%;

      &--unset-order {
        order: unset !important;
      }
    }

		&-content {
      width: 30%;
			@include flex(column, center);
		}

		&-title {
			margin-bottom: 2rem;
			@include font(2.4rem, 3.2rem, 600);
		}
  }

  @include max-width('tablet') {
    &__article {
      flex-direction: column;
      align-items: center;

      &:nth-child(even) &-img {
        order: unset;
      }

      &-content {
        width: 100%;
        margin-top: 2rem;
      }
    }
  }
}
