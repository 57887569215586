// !faq
.faq {
	padding: 5rem 0 7rem;
	background-color: var(--bg-gray);

	.faq-wrapper {
		@include flex(column, null, center);

		.faq__heading {
			margin-bottom: 6rem;
			@include font(3.6rem, null, bold);
			text-transform: capitalize;
		}

		.answer-content {
			&--body {
				a {
					color: var(--primary);
				}
			}
		}
	}

	&--bg-white {
		background-color: var(--white);
	}
}
