.security-status {
	.status-card {
		border-radius: 5px;
		background-color: #fff;
		box-shadow: 2px 6px 15px 0 rgb(69 65 78 / 10%);
		border: 0;

		&__title {
			border-bottom: 0;
			padding: 1rem 1.25rem;
			background-color: transparent;
		}
	}

	.modal-status-card {
		border-radius: 5px;
		background-color: #fff;
		box-shadow: 2px 6px 15px 0 rgb(69 65 78 / 10%);
		border: 0;

		&__title {
			margin: 0;
			color: #2a2f5b;
			font-size: 20px;
			font-weight: 400;
			line-height: 1.6;
		}
	}

	// Modal slide-out
	.modal-dialog-slideout {
		min-height: 100%;
		margin: 0 0 0 auto;
		background: #fff;
	}
	.modal.fade .modal-dialog.modal-dialog-slideout {
		-webkit-transform: translate(100%, 0) scale(1);
		transform: translate(100%, 0) scale(1);
	}
	.modal.fade.show .modal-dialog.modal-dialog-slideout {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		display: flex;
		align-items: stretch;
		-webkit-box-align: stretch;
		height: 100%;
	}
	.modal.fade.show .modal-dialog.modal-dialog-slideout .modal-body {
		overflow-y: auto;
		overflow-x: hidden;
	}
	.modal-dialog-slideout .modal-content {
		border: 0;
	}
	.modal-dialog-slideout .modal-header,
	.modal-dialog-slideout .modal-footer {
		height: 6rem;
		display: block;
	}
}
