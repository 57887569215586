.footer {
	padding: 3.5rem 0;
	background-color: var(--bg-gray);

	&__wrapper {
		@include flex(row, space-between, center, wrap);
	}

	&__social-icons {
		@include flex();

		span:not(:last-child) {
			margin-right: 1.5rem;
		}
	}

	@include max-width("phone") {
		&__wrapper {
			@include flex(column);
		}

		&__social-icons {
			margin-top: 2.5rem;
		}
	}
}
