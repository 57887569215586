.bg-img__wrapper .pricing-plans .pricing-plans__wrapper {
	.ant-tabs {
		$tabs: '.ant-tabs';
		$tab: '.ant-tabs-tab';
		$tab-animated: '.ant-tabs-ink-bar';
		$tab-btn: '.ant-tabs-tab-btn';
		$nav: '.ant-tabs-nav';
		$tab-animated-z-index: -100;
		overflow: unset;

		&-nav {
			padding: 5rem;

			#{$nav}-wrap {
				overflow: unset;
			}

			#{$nav}-wrap #{$nav}-list {
				border-radius: 1rem;
				border: thin solid var(--primary);

				#{$tab} {
					padding: 2.5rem 4rem;
					color: var(--primary);
					text-align: center;
					text-transform: uppercase;
					@include font(1.8rem, 2.3rem, 700);

					.psg-grant {
						display: block;
						text-transform: none;
						@include font(1.4rem, 1.8rem, 500);
					}

					&:not(:nth-last-child(2)) {
						border-right: thin solid currentColor;
					}

					&:nth-child(2)::before {
						content: 'save up to 20%';
						position: absolute;
						top: -5.1rem;
						left: -11.6rem;

						color: var(--primary);
						font-family: var(--promo-font);
						@include font(2.1rem, 2.5rem, 700);
					}

					&:nth-child(2)::after {
						content: '';
						position: absolute;
						top: -4.5rem;
						left: 7rem;

						@include size(3rem);
						background: url('/images/curved-arrow.png') no-repeat;
						background-size: contain;
					}

					&:nth-child(3) {
						.eligibility-link {
							display: unset;

							&::after {
								content: 'View eligibility';
								position: absolute;
								top: 6rem;
								right: -15.5rem;

								color: var(--primary);
								font-size: 1.4rem;
								font-family: var(--promo-font);
								text-transform: capitalize;
								text-decoration: underline;
								cursor: pointer;
							}
						}
					}

					&:nth-child(3)::before {
						content: 'additional 80% grant \00000a for Singapore SMEs';
						position: absolute;
						top: 1rem;
						right: -25rem;

						color: var(--primary);
						white-space: pre;
						text-align: left;
						font-family: var(--promo-font);
						@include font(1.4rem, 2.3rem, 700);
					}

					&:nth-child(3)::after {
						content: '';
						position: absolute;
						top: -1.5rem;
						right: -6.5rem;

						@include size(3rem);
						background: url('/images/curved-arrow.png') no-repeat;
						background-size: contain;
						@include transform(scaleX(-1) rotate(-45deg));
					}

					&.ant-tabs-tab-active #{$tab-btn} {
						color: var(--white);
						font-weight: 700;
					}

					&:first-child.ant-tabs-tab-active ~ #{$tab-animated} {
						border-top-left-radius: 1rem;
						border-bottom-left-radius: 1rem;
					}

					&:nth-last-child(2).ant-tabs-tab-active ~ #{$tab-animated} {
						border-top-right-radius: 1rem;
						border-bottom-right-radius: 1rem;
					}
				}

				#{$tab-animated} {
					height: 100%;
					background: var(--primary);
					z-index: $tab-animated-z-index;
				}
			}
		}

		@include max-width('desktop') {
			& {
				width: 100vw;
				padding-left: 1.5rem;
				padding-right: 1.5rem;
			}

			#{$nav} {
				padding-top: 0;
			}

			#{$nav}-wrap {
				overflow-x: unset;
			}

			#{$nav}-wrap #{$nav}-list {
				#{$tab} {
					padding: 1rem;
					font-size: medium;
					font-weight: 500;
					&.ant-tabs-tab-active #{$tab-btn} {
						font-weight: 500;
					}
				}
			}

			#{$tab} #{$tab-btn} .eligibility-link,
			#{$tab}:nth-child(2)::after,
			#{$tab}:nth-child(2)::before,
			#{$tab}:nth-last-child(2)::after,
			#{$tab}:nth-last-child(2)::before {
				display: none !important;
			}
		}
	}
}

// overwrote active color
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
	color: var(--white);
}
